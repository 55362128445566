<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Nueva Servicio..." icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
						<Button label="Exportar a Excel"  class="p-button-warning  p-mr-2" v-on:click="download" />
						<!-- <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedServicios || !selectedServicios.length" /> -->
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="servicios" v-model:selection="selectedServicios" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} servicios">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Catálogo de Servicios</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					<!-- <Column field="id_servicio" header="Id" :sortable="true"></Column> -->
					<Column field="nombre" header="Servicio" :sortable="true"></Column>
                    <Column field="descripcion" header="Descripción" :sortable="true"></Column>
                    <Column field="activo" header="Activo" :sortable="true">
						<template #body="slotProps">
                                    {{turno(slotProps.data.activo)}}
                        </template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editServicio(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteServicio(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="servicioDialog" :style="{width: '450px'}" header="Servicio" :modal="true" class="p-fluid">
					<div class="p-field">
						<label for="nombre">Servicio *</label>
						<InputText id="nombre" v-model.trim="servicio.nombre" required="true" autofocus :class="{'p-invalid': submitted && !servicio.nombre}" />
						<small class="p-invalid" v-if="submitted && !servicio.nombre">El nombre de la servicio es requerido</small>
					</div>

					<div class="p-field">
						<label for="descripcion">Descripción</label>
						<Textarea id="descripcion" v-model="servicio.descripcion" required="false" rows="3" cols="20" />
					</div>

                    <div class="p-field-checkbox">
                        <Checkbox id="activo" name="activo" :binary="true"  v-model="servicio.activo" />
                        <label for="activo">Activo</label>
                    </div>
                    <!--
					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="activo">Activo</label>                            
							<InputNumber id="activo" v-model="servicio.activo" integeronly />
						</div>
					</div>
                    -->
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveServicio" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteServicioDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="servicio">¿Desea eliminar el servicio <b>{{servicio.nombre}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteServicioDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteServicio" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteServiciosDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="servicio">¿Desea eliminar los servicios seleccionados?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteServiciosDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteSelectedServicios" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import ServicioService from '../service/ServicioService';
import XLSX from 'xlsx'

export default {
	data() {
		return {
			servicios: null,
			servicioDialog: false,
			deleteServicioDialog: false,
			deleteServiciosDialog: false,
			servicio: {},
			selectedServicios: null,
			filters: {},
			submitted: false, 
			serviciosPos:[], 
			descripcion:null,
			activo:false,
			ServiciosService:null,
			MuestrasActivas:null,
		}
	},
	servicioService: null,
	created() {
		this.servicioService = new ServicioService();
	},
	mounted() {
		this.servicioService.getServicios().then(data => this.servicios = data);
		this.servicioService.getServiciosExport().then(data => {this.MuestrasActivas = data.data2});
	},
	methods: {
		openNew() {
			this.servicio = {};
			this.submitted = false;
			this.servicioDialog = true;
			this.servicio.activo=true;
		},
		hideDialog() {
			this.servicioDialog = false;
			this.submitted = false;
		},
		saveServicio() {
			this.submitted = true;
           
			if (this.servicio.nombre.trim()) {
				if (this.servicio.id_servicio>0) {

					this.serviciosPos.push({
						"id_servicio":this.servicio.id_servicio,
						"nombre":this.servicio.nombre,
						"descripcion":this.descripcion,
						"activo":this.servicio.activo,
					});
					 
					this.servicioService.updateServicios(this.servicio).then();
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Servicio actualizado', life: 3000});
					this.serviciosPos=[];
					document.location.reload();
				}
				else {
					if( typeof this.servicio.descripcion != 'undefined'){
						this.descripcion=this.servicio.descripcion;
					}
					if( typeof this.servicio.activo != 'undefined'){
						this.activo=this.servicio.activo;
					}

					this.serviciosPos.push({
						"nombre":this.servicio.nombre,
						"descripcion":this.descripcion,
						"activo":this.activo,
					});

					 
					this.servicioService.createServicios(this.serviciosPos[0]).then();
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Servicio creado', life: 3000});					
					this.serviciosPos=[];
					document.location.reload();
					
				}

				this.servicioDialog = false;
				 
			}
		},
		editServicio(servicio) {
			this.servicio = {...servicio};
			this.servicioDialog = true;
		},
		confirmDeleteServicio(servicio) {
			this.servicio = servicio;
			this.deleteServicioDialog = true;
		},
		deleteServicio() {
			var validateFecha=true;

			this.serviciosPos.push({
				"id_servicio":this.servicio.id_servicio,
				"nombre":this.servicio.nombre,
				"descripcion":this.descripcion,
				"activo":0,
			});

			for(var i in this.MuestrasActivas)
			{
				console.log(this.MuestrasActivas[i]);
				if(this.servicio.id_servicio==this.MuestrasActivas[i].id_servicio)
				{ 
					validateFecha=false;
				}                  	
			} 

			if(validateFecha==true){
				this.servicioService.updateServicios(this.serviciosPos[0]).then(document.location.reload());

				this.$toast.add({severity:'success', summary: 'Successful', detail: 'Servicio eliminado', life: 3000}); 
				this.deleteServicioDialog = false;
				document.location.reload()
			}else{
				this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'No se puede eliminar.El paciente tiene muestras asignadas', life: 3000});
			}

			this.deleteServicioDialog = false;
			
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.servicios.length; i++) {
				if (this.servicios[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
            let id = this.servicios.length + 1;
            /*
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            */
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteServiciosDialog = true;
		},
		deleteSelectedServicios() {
			this.servicios = this.servicios.filter(val => !this.selectedServicios.includes(val));
			this.deleteServiciosDialog = false;
			this.selectedServicios = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Servicios eliminados', life: 3000});
		},
		turno(value){
			if(value ==true){
				return 'Activo'
			}else{
				return 'Inactivo'
			}
		},
		download : function() {
			this.servicioService.getServiciosExport().then(data => {
			this.ServiciosService = data.servicios
				if(this.ServiciosService != null){
				const data = XLSX.utils.json_to_sheet(this.ServiciosService)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,'CatalogoServicios.xlsx')
				}
			});
	
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.servicio-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .servicio-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.servicio-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-activo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-inactivo {
		background: #FFCDD2;
		color: #C63737;
	}
}
</style>
